import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guard/auth.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/monthley' },
  { path: '', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule), canActivate: [AuthGuard] },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: "signup", loadChildren: () => import('./public/signup/signup.module').then(m => m.SignupModule) },
  { path: 'forgot-password', loadChildren: () => import('./public/forget-passwd/forget-passwd.module').then(m => m.ForgetPasswdModule) },
  { path: "activation/:activationId/:email", loadChildren: () => import('./public/signup/activation/activation.module').then(m => m.ActivationModule) },
  { path: "reset-password/:email/:token", loadChildren: () => import('./public/reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
  { path: "payment/:sessionId", loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentModule) },
  { path: "receipt/payment", loadChildren: () => import('./pages/payment-online/payment-online.module').then(m => m.PaymentOnlineModule) },
  { path: "stmt/:sessionId", loadChildren: () => import('./public/stmt-online/stmt-online.module').then(m => m.StatementOnlineModule) },
  { path: "rcpt/:uuid", loadChildren: () => import('./public/rcpt-online/rcpt-online.module').then(m => m.RcptOnlineModule) },
  { path: 'privacy', loadChildren: () => import('./public/privacy/privacy.module').then(m => m.PrivacyModule) },
  { path: 'monthleylanding', loadChildren: () => import('./landing-page/landing-page.module').then(m => m.LandingPageModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
