import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IconsProviderModule } from './icons-provider.module';
import { NgZorroAntdModule, NZ_I18N, en_US, NzConfig, NZ_CONFIG } from 'ng-zorro-antd';
import { FormsModule, FormBuilder } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { InterceptorService } from './shared/services/interceptor.service';
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guard/auth.guard';
import { Data } from './shared/providers/data/data';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const ngZorroConfig: NzConfig = {
  message: { nzMaxStack: 1 },
};


export function HttpLoaderFactory(http: HttpClient) {
  // return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    IconsProviderModule,
    NgZorroAntdModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgbModule,
  ],
  providers: [
    {
      provide: NZ_CONFIG,
      useValue: ngZorroConfig
    },
    {
      provide: NZ_I18N,
      useValue: en_US
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    FormBuilder,
    AuthGuard,
    Data
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
