import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd';
import { Router, NavigationStart, NavigationCancel, NavigationError, ActivatedRoute } from '@angular/router';
import { UtilService } from './shared/services/util.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.translate.use(
      localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en'
    );
    this.translate.setDefaultLang('en');
    this.translate.getTranslation(localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en').subscribe(
      res => {
      }
    )

  }
}
