import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class Data {

    public storage: any;

    public counterSelected = new EventEmitter();

    public constructor() { }

    setCounter(data: boolean) {
        this.counterSelected.emit(data);
    }
}
