import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, EMPTY, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(
    private router: Router,
    private modal: NzModalService
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (localStorage.getItem("data")) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${localStorage.getItem("data")}`
        }
      });
    }
    return next.handle(req).pipe(
      catchError(err => {
        if (err.status === 401 || err.status === 403) {
          this.modal.closeAll();
          localStorage.clear();
          this.router.navigate(["/login"]);
          return throwError(err);
        } else {
          return throwError(err);
        }
      })
    );

  }
}
